<template>
  <div>
    <v-content>
      <v-toolbar-title>
        <progress-step :step="page.step"></progress-step>
      </v-toolbar-title>
      <v-container>
        <v-card style="padding: 0px !important;" flat>
          <v-card-title style="margin-bottom: 50px">
            Please confirm your Debit Card:
          </v-card-title>
          <v-row>
            <v-col cols="12" class="p-t-40">
              <v-card-text>
                <v-row style="padding-left: 11px">
                  Card Number:
                  <div style="margin-left: 10px">
                    <v-img height="25px" width="40px" src="/static/images/card-visa.png"></v-img>
                  </div>
                  <div style="float: right;margin-left: 2px">
                    <v-img height="25px" width="40px" src="/static/images/card-mastercard.png"></v-img>
                  </div>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="10">
              <v-card-text class="p-t-0">
                <input readonly
                       v-model="instantCard.maskedCardNumber"
                       style="padding: 5px; border: 1px solid rgb(133, 133, 133); width: 100%; border-radius: 3px; text-align: left; outline-color: var(--v-primary-base)"/>
              </v-card-text>
            </v-col>
            <v-col cols="2">
              <v-btn
                small
                class="mx-2"
                fab
                dark
                color="cyan"
                @click="showModalInstantCard = true"
              >
                <v-icon dark>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card-text>Expiration Date:</v-card-text>
              <v-card-text class="p-t-0">
                <v-row>
                  <v-col class="p-t-0" cols="7">
                    <v-select
                      disabled
                      :items="mouth"
                      v-model="instantCard.expMonth"></v-select>
                  </v-col>
                  <v-col class="p-t-0" cols="5">
                    <v-select
                      disabled
                      :items="year"
                      v-model="instantCard.expYear"></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-content>
    <v-footer>
      <v-btn
        x-large
        block
        color="primary"
        class="width-150"
        @click="next"
      >NEXT
      </v-btn>
    </v-footer>

    <v-dialog max-width="600px" v-model="showModalInstantCard">
      <instant-card></instant-card>
    </v-dialog>
  </div>

</template>

<script>

import InstantCard from '../../components/ailurus/InstantCardModal'
import ProgressStep from '../../components/progress/ProgressStep'

export default {
  components: { ProgressStep, InstantCard },
  data () {
    return {
      showModalInstantCard: false,
      mouth: [
        { text: 'JAN', value: 1 },
        { text: 'FEB', value: 2 },
        { text: 'MAR', value: 3 },
        { text: 'APR', value: 4 },
        { text: 'MAY', value: 5 },
        { text: 'JUN', value: 6 },
        { text: 'JUL', value: 7 },
        { text: 'AUG', value: 8 },
        { text: 'SEP', value: 9 },
        { text: 'OCT', value: 10 },
        { text: 'NOV', value: 11 },
        { text: 'DEC', value: 12 }
      ],
      year: [],
      instantCard: {
        maskedCardNumber: '',
        expMonth: '',
        expYear: '',
        loanId: undefined
      },
      step: 'bank'
    }
  },
  methods: {
    next () {
      this.$router.push('/authentication/advertising')
    }
  },
  created () {
    const year = new Date().getFullYear()
    this.instantCard.expYear = year
    for (let i = 0; i < 10; i++) {
      this.year.push(year + i)
    }
    window.addEventListener('popstate', function () {
      history.pushState(null, null, document.URL)
    })
  }
}
</script>
